import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService, Store } from '../shared/auth.service';
import { Helper } from '../shared/helper';

@Injectable({ providedIn: 'root' })
export class AdminSettingService {
  private _settingChanges = new BehaviorSubject<any>(null);
  _settingObservable = this._settingChanges.asObservable();

  constructor(private helper: Helper,private _auth:AuthService) {}

  fetchAdminSetting(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_SETTING_DETAIL,{})
  }

  fetchInstallationSetting(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_INSTALLATION_SETTING,{})
  }

  getBankDetail(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_BANK_DETAIL,json)
  }

  updateBankDetail(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_BANK_DETAIL,json)
  }

  updateAdminSetting(json){
    // console.log(json.app_name)
    if(json.app_name != undefined){
      var app_name = json.app_name
      localStorage.setItem('appName', app_name)
    }
    console.log(json);
    
    this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_ADMIN_SETTING,json).then(res=>{
      if(res.success){
        // console.log(res)
        this._settingChanges.next({})
      }
    })
  }

  updateInstallationSetting(json){
    this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_INSTALLATION_SETTING,json).then(res=>{
      if(res.success){
        this._settingChanges.next({})
      }
    })
  }

  updateAdminLogoSetting(form_data){
    this.helper.http_post_method_requester(this.helper.POST_METHOD.UPLOAD_LOGO_IMAGES,form_data)
  }

  updatePushNotificationSetting(form_data){
    this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_PUSH_NOTIFICATION_SETTING,form_data).then(res=>{
      if(res.success){
        this._settingChanges.next({})
      }
    })
  }
}
