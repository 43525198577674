<div class="menu" (click)="menuClicked($event)">
    <div class="main-menu">
        <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">
            <ul class="list-unstyled">
                <li *ngFor="let item of filteredMenuItems(menuItems)"
                    [ngClass]="{'active': (selectedParentMenu === item.to && viewingParentMenu === '') || viewingParentMenu === item.to}">
                    <a *ngIf="item.newWindow" [href]="item.to" rel="noopener noreferrer" target="_blank">
                        <i [ngClass]="item.icon"></i>{{item.label | translate}}</a>
                    <a *ngIf="!item.newWindow && item.subs &&  item.subs.length>0"
                        (click)="openSubMenu($event,item); false" [href]="item.to">
                        <i [ngClass]="item.icon"></i> {{item.label | translate}}</a>
                    <a *ngIf="!item.newWindow && (!item.subs || (item.subs &&  item.subs.length===0))"
                        (click)="changeSelectedParentHasNoSubmenu(item.to); false" [routerLink]="item.to">
                        <i [ngClass]="item.icon"></i> {{item.label | translate}}</a>

                </li>
            </ul>
        </perfect-scrollbar>
    </div>
    <div class="sub-menu">
        <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">
            <ul class="list-unstyled" *ngFor="let item of filteredMenuItems(menuItems)" [attr.data-parent]="item.to"
                [ngClass]="{'d-block': (selectedParentMenu === item.to && viewingParentMenu==='') || viewingParentMenu === item.to}">
                <li *ngFor="let sub of filteredMenuItems(item.subs)"
                    [ngClass]="{'active': currentUrl === sub.to ,'has-sub-item' : sub.subs&& sub.subs.length>0 }">
                    <a *ngIf="sub.newWindow" [href]="sub.to" rel="noopener noreferrer" target="_blank">
                        <i [ngClass]="sub.icon"></i>
                        <span class="d-inline-block">{{sub.label | translate}}</span>
                    </a>
                    <a *ngIf="!sub.newWindow && (!sub.subs || sub.subs.length<=0)" [routerLink]="[sub.to]"
                        routerLinkActive="active">
                        <i [ngClass]="sub.icon"></i>
                        <span class="d-inline-block">{{sub.label | translate}}</span>
                    </a>
                    <a (click)="toggleCollapse(sub.to)" *ngIf="sub.subs && sub.subs.length > 0"
                        [ngClass]="{'rotate-arrow-icon opacity-50 c-pointer': true, 'collapsed': closedCollapseList.includes(sub.to)}">
                        <i class="simple-icon-arrow-down"></i>
                        <span class="d-inline-block">{{sub.label | translate}}</span>
                    </a>
                    <div [collapse]="closedCollapseList.includes(sub.to)" [isAnimated]="true">
                        <div *ngIf="sub.subs && sub.subs.length > 0">
                            <ul class="list-unstyled inner-level-menu">
                                <li *ngFor="let deepSub of filteredMenuItems(sub.subs)"
                                    [ngClass]="{'active': currentUrl === deepSub.to}">
                                    <a *ngIf="deepSub.newWindow" [href]="deepSub.to" rel="noopener noreferrer"
                                        target="_blank">
                                        <i [ngClass]="deepSub.icon"></i>
                                        <span class="d-inline-block">{{deepSub.label | translate}}</span>
                                    </a>
                                    <a *ngIf="!deepSub.newWindow && (!deepSub.subs || deepSub.subs.length<=0)"
                                        [routerLink]="[deepSub.to]" routerLinkActive="active">
                                        <i [ngClass]="deepSub.icon"
                                            *ngIf="deepSub.role !== '/admin/dietay&filter-tags'"></i>
                                        <i [ngClass]="deepSub.icon"
                                            *ngIf="deepSub.role === '/admin/dietay&filter-tags'">

                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor"
                                                height="16px" width="16px" version="1.1" id="Layer_1"
                                                viewBox="0 0 512 512" xml:space="preserve">
                                                <g>
                                                    <g>
                                                        <g>
                                                            <path
                                                                d="M256,0C114.618,0,0,114.618,0,256s114.618,256,256,256s256-114.618,256-256S397.382,0,256,0z M256,469.333 c-117.818,0-213.333-95.515-213.333-213.333S138.182,42.667,256,42.667S469.333,138.182,469.333,256S373.818,469.333,256,469.333 z">
                                                            </path>
                                                            <path
                                                                d="M384,253c-11.782,0-21.333,9.551-21.333,21.333C362.667,328.434,312.101,379,256,379s-106.667-50.566-106.667-106.667 c0-11.782-9.551-21.333-21.333-21.333c-11.782,0-21.333,9.551-21.333,21.333c0,79.665,69.669,149.333,149.333,149.333 s149.333-69.669,149.333-149.333C405.333,264.551,395.782,253,384,253z" />

                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>

                                        </i>
                                        <span class="d-inline-block">{{deepSub.label | translate |
                                            slice:0:15}}{{(deepSub.label | translate).length > 15 ? '...' : ''}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
            </ul>
        </perfect-scrollbar>
    </div>
</div>